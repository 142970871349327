
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, IonInput, IonButton, modalController } from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import { openToast } from "@/services/toast";
import apiUser from "@/services/users";

export default defineComponent({
    name: "RecoveryPassword",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        IonButton,
        IonInput,
    },
    setup() {
        const loading = ref(false);
        const setOpen = (state: boolean) => (loading.value = state);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
         const closeModal = () => {
            modalController.dismiss();
        };

        const userEmail = ref(null);
        const controlCode = ref(null);
        const password = ref(null);
        const passwordConfirm = ref(null);
        const isInputCodeVisible = ref(false);
        const isPasswordVisible = ref(false);
        const isFirstStepComplete = ref(false);
        const loadingRequest = ref(false);
        const loadingRequestPassword = ref(false);

        async function setPassword() {
            if (!controlCode.value || controlCode.value.trim() === "") {
                openToast("Il campo codice verifica non può essere vuoto", "toast_danger");
                return;
            }
            if (!password.value || password.value.trim() === "") {
                openToast("Il campo password non può essere vuoto", "toast_danger");
                return;
            }
            if (!passwordConfirm.value || passwordConfirm.value.trim() === "") {
                openToast("Il campo conferma password non può essere vuoto", "toast_danger");
                return;
            }
            if (password.value !== passwordConfirm.value) {
                openToast("Le password non corrispondono", "toast_danger");
                return;
            }

            loadingRequestPassword.value = true;
            try {
                const res = await apiUser.changePassword(userEmail.value, controlCode.value, password.value, passwordConfirm.value);
                if (res.status === 200 && res.data.status === 1) {
                    openToast(`${res.data.message}`, "toast_danger");
                    return;
                }
                //mostro messaggio e input controllo e nuova password
                if (res.status === 200 && res.data.status === 0) {
                    openToast(`${res.data.message}`, "toast_success");
                    closeModal();
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la modifica della password", "toast_danger");
                loadingRequestPassword.value = false;
                return;
            } finally {
                loadingRequestPassword.value = false;
            }
        }

        async function handleRecoveryPassword() {
            if (!userEmail.value || userEmail.value.trim() === "") {
                openToast("Il campo email non può essere vuoto", "toast_danger");
                return;
            }

            loadingRequest.value = true;
            try {
                const res = await apiUser.getVerificationCode(userEmail.value);
                if (res.status === 200 && res.data.status === 1) {
                    openToast(`${res.data.message}`, "toast_danger");
                    return;
                }
                //mostro messaggio e input controllo e nuova password
                if (res.status === 200 && res.data.status === 0) {
                    openToast(`${res.data.message}`, "toast_success");
                    //userEmail.value = null;
                    isFirstStepComplete.value = true;
                    isInputCodeVisible.value = true;
                    isPasswordVisible.value = true;
                }
            } catch (error) {                
                openToast("Si è verificato un errore durante l'invio dell'email", "toast_danger");
                loadingRequest.value = false;
                return;
            } finally {
                loadingRequest.value = false;
            }
        }

        return {
            arrowBackOutline,
            loading,
            setOpen,
            closeModal,
            handleRecoveryPassword,
            userEmail,
            loadingRequest,
            isFirstStepComplete,
            isInputCodeVisible,
            isPasswordVisible,
            controlCode,
            password,
            passwordConfirm,
            setPassword,
            loadingRequestPassword
        };
    },
});
